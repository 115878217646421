import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Codes, OntimizeEEService, Observable, ServiceResponse, OTranslateService } from 'ontimize-web-ngx';
import { share } from 'rxjs/operators';
import { KeycloakService } from './keycloak.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MultitenantService extends OntimizeEEService {
	private keycloakService: KeycloakService;
	private translator: OTranslateService;
	path: string;

	constructor(injector: Injector) {
		super(injector);
		this.keycloakService = this.injector.get(KeycloakService);
		this.translator = this.injector.get(OTranslateService);
	}

	public configureService(config: any): void {
		super.configureService(config);
		if (config.path !== undefined) {
			this.path = config.path;
		}
	}

	private buildHeadersBase(contentType): HttpHeaders {
		let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
		if (contentType) headers = headers.set('Content-Type', contentType);
		headers = headers.set('Authorization', 'Bearer ' + this.authService.getSessionInfo().id);
		let tenantId = environment.tenantId;
		if (tenantId && tenantId.length > 0) headers = headers.set('X-Tenant', tenantId);
		return headers;
	}

	public buildHeaders(): HttpHeaders {
		return this.buildHeadersBase('application/json;charset=UTF-8');
	}

  public buildMultipartHeaders(): HttpHeaders {
    return this.buildHeadersBase(undefined);
  }

	public buildUnauthorizedHeaders(): HttpHeaders {
		return new HttpHeaders()
			.set('Access-Control-Allow-Origin', '*')
			.set('Content-Type', 'application/json;charset=UTF-8')
			.set('X-Tenant', environment.tenantId);
	}

	public endsession(user: string, sessionId: any): Observable<number> {
		const dataObservable: Observable<any> = new Observable(observer => {
      this.keycloakService.logout(Codes.LOGIN_ROUTE);
			observer.next(0);
		});
		return dataObservable.pipe(share());
	}

	languageQuery(kv: object, av: Array<string>, entity: string, sqltypes?: object): Observable<ServiceResponse> {
		kv['iso'] = this.translator.getCurrentLang().toUpperCase();
		return this.getQuery(kv, av, entity, sqltypes);
	}

	getQuery(kv: object, av: Array<string>, entity: string, sqltypes?: object): Observable<ServiceResponse> {
		let url: string;
		if (this.path !== undefined) {
			url = `${this.urlBase}${this.path}/${entity}`;
		} else {
			url = `${this.urlBase}/${entity}`;
		}
		return this.doRequest({
			method: 'GET',
			url,
			options: { headers: this.buildHeaders(), params: { ...kv } },
		});
	}
}
