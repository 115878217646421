import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';
import { APP_CONFIG, ONTIMIZE_PROVIDERS, OntimizeWebModule, O_MAT_ERROR_OPTIONS, O_PERMISSION_SERVICE } from 'ontimize-web-ngx';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CONFIG } from './app.config';
import { MainService } from './shared/services/main.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { StyleManager } from './shared/services/style-manager';
import { ThemeService } from './shared/services/theme.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule } from 'keycloak-angular';
import { initializer } from './util/app-init';
import { TenantInterceptor } from './shared/tenant-interceptor';
import { KeycloakService } from './shared/services/keycloak.service';
import { CustomPermissionsService } from './custom-permission.service';

// Standard providers...
// Defining custom providers (if needed)...
export const customProviders: any = [
	StyleManager,
	ThemeService,
	MainService,
	{ provide: O_MAT_ERROR_OPTIONS, useValue: { type: 'lite' } },
	{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
];

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		OntimizeWebModule.forRoot(CONFIG),
		OntimizeWebModule,
		AppRoutingModule,

		HttpClientModule,
		KeycloakAngularModule,

		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
	],
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	providers: [
		{ provide: APP_CONFIG, useValue: CONFIG },
		{
			provide: APP_INITIALIZER,
			useFactory: initializer,
			multi: true,
			deps: [KeycloakService],
		},
		CookieService,
		{ provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
		{ provide: O_PERMISSION_SERVICE, useValue: CustomPermissionsService },
		ONTIMIZE_PROVIDERS,
		...customProviders,
	],
})
export class AppModule {}
