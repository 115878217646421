// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//TODO tenantId and realm has to change in the form to select the tenant to config.
export const environment = {
	apiEndpoint: 'https://aries-config-back.dev.imatia.cloud',
	production: true,
	tenantId: 'aries_config',
  marketplace: 'perte',
	keycloakUrl: 'https://login.vec.dev.imatia.cloud',
	realm: 'aries_config',
	client: 'config-frontend',
	paginationValues: [6, 12, 24],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
