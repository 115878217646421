export const SERVICE_CONFIG: Object = {
	users: {
		path: '/users',
	},
	addresses: {
		path: '/addresses',
	},
	menuPermissions: {
		path: '/userMgmt/menuPermissions',
	},
};
