import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakEvent, KeycloakEventType, KeycloakService as KCService } from 'keycloak-angular';
import { KeycloakConfig, KeycloakInitOptions, KeycloakLoginOptions } from 'keycloak-js';
import { LoginStorageService, SessionInfo } from 'ontimize-web-ngx';
import { environment } from 'src/environments/environment';
import { CookieService } from './cookie.service';

export function getEnvVariable(name: string, defaultvalue: string) {
	let result: string = defaultvalue;

	if (window['__env'] !== undefined && window['__env'][name] !== undefined && window['__env'][name] !== null && window['__env'][name] !== '') {
		result = window['__env'][name];
	}

	return result;
}

@Injectable({ providedIn: 'root' })
export class KeycloakService {
	constructor(
		private httpClient: HttpClient,
		private keycloakService: KCService,
		private loginStorageService: LoginStorageService,
		private locationStrategy: LocationStrategy,
		private cookieService: CookieService
	) {
		this.keycloakService.keycloakEvents$.subscribe(async (e: KeycloakEvent) => {
			if (e.type === KeycloakEventType.OnTokenExpired) {
				this.keycloakService
					.updateToken(20)
					.then(refreshed => {
						if (refreshed) {
							this.keycloakService.getToken().then(token => {
								let sessionInfo = this.loginStorageService.getSessionInfo();
								if (sessionInfo) {
									(sessionInfo as any).id = token;
									this.loginStorageService.storeSessionInfo(sessionInfo);
								}
							});
						} else {
							console.log('Token not refreshed ' + new Date());
						}
					})
					.catch(err => {
						console.log('Failed to refresh token: ' + err);
					});
			} else if (e.type === KeycloakEventType.OnAuthSuccess) {
				this.keycloakService.getToken().then(token => {
					if (token) {
						this.keycloakService.loadUserProfile().then(profile => {
							localStorage.setItem('keycloak-loginhint', profile.username);
							localStorage.setItem('keycloak-companyUUIdhint', profile['attributes'].companyUUId);
							let sessionInfo: SessionInfo = {
								id: token,
								user: profile.username,
							};
							this.loginStorageService.storeSessionInfo(sessionInfo);
						});
					}
				});
			} else if (e.type === KeycloakEventType.OnAuthLogout) {
				this.cookieService.delete('implatform_tenantId');
				localStorage.removeItem('tenantId');
				localStorage.removeItem('keycloak-url');
				localStorage.removeItem('keycloak-realm');
				localStorage.removeItem('keycloak-client');
				localStorage.removeItem('keycloak-loginhint');
				localStorage.removeItem('keycloak-companyUUIdhint');

				this.loginStorageService.sessionExpired();
			}
		});
	}

	public getExcludedUrls() {
		return ['/tenant', '/userTenant', '/userTenants'];
	}

	public getKeycloak() {
		return this.keycloakService;
	}

	public getTenant() {
		return localStorage.getItem('tenantId');
	}

	public getUrl() {
		let result = undefined;
		if (this.keycloakService.getKeycloakInstance()) result = this.keycloakService.getKeycloakInstance().authServerUrl;
		return result;
	}

	public getRealm() {
		let result = undefined;
		if (this.keycloakService.getKeycloakInstance()) result = this.keycloakService.getKeycloakInstance().realm;
		return result;
	}

	public getClientId() {
		let result = undefined;
		if (this.keycloakService.getKeycloakInstance()) result = this.keycloakService.getKeycloakInstance().clientId;
		return result;
	}

	public initialize(): Promise<void> {
		let tenant = localStorage.getItem('tenantId');
		let url = localStorage.getItem('keycloak-url');
		let realm = localStorage.getItem('keycloak-realm');
		let clientId = localStorage.getItem('keycloak-client');
		let prompt = localStorage.getItem('keycloak-prompt');

		if (tenant && url && realm && clientId) {
			if (prompt === 'none') {
				localStorage.removeItem('keycloak-prompt');
				return new Promise<void>(async (resolve, reject) => {
					this.config(url, realm, clientId)
						.then(() => {
							resolve();
						})
						.catch(err => {
							reject(err);
						});
				});
			} else {
				return this.login(tenant, url, realm, clientId);
			}
		} else {
			tenant = this.cookieService.get('implatform_tenantId');
			if (tenant) {
				let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*').set('Content-Type', 'application/json;charset=UTF-8');
				let url = getEnvVariable('apiUrl', environment.apiEndpoint) + '/tenant?tenantId=' + tenant;
				let options = {
					headers: headers,
					responseType: 'text' as 'json',
				};
				this.httpClient.get(url, options).subscribe(
					resp => {
						if (resp) {
							let tenantInfo = JSON.parse(resp.toString());
							if (tenantInfo) {
								return this.login(tenant, tenantInfo.url, tenantInfo.realm, tenantInfo.client);
							}
						}
					},
					err => {
						console.log('Failed to get tenant info: ' + err.message);
					}
				);
			}
		}
		return new Promise<void>(async resolve => resolve());
	}

	public isLoggedIn(): Promise<boolean> {
		if (this.keycloakService.getKeycloakInstance()) {
			return this.keycloakService.isLoggedIn();
		} else {
			return new Promise(async resolve => resolve(false));
		}
	}

	public login(tenant: string, url: string, realm: string, clientId: string, redirectUrl?: string, username?: string): Promise<void> {
		this.cookieService.set('implatform_tenantId', tenant);
		localStorage.setItem('tenantId', tenant);
		localStorage.setItem('keycloak-url', url);
		localStorage.setItem('keycloak-realm', realm);
		localStorage.setItem('keycloak-client', clientId);

		return new Promise(async (resolve, reject) => {
			this.config(url, realm, clientId)
				.then(autenticated => {
					if (autenticated === true) {
						resolve();
					} else {
						let redirectUri = undefined;
						if (redirectUrl) redirectUri = this.getFullUrl(redirectUrl);

						let koptions: KeycloakLoginOptions = {};
						koptions.redirectUri = redirectUri;
						if (username) {
							koptions.loginHint = username;
							localStorage.setItem('keycloak-loginhint', username);
						} else {
							koptions.prompt = 'none';
							localStorage.setItem('keycloak-prompt', 'none');
						}
						this.keycloakService
							.login(koptions)
							.then(() => {
								resolve();
							})
							.catch(err => {
								reject('Failed to login: ' + err);
							});
					}
				})
				.catch(err => {
					reject('Failed to configure keycloak: ' + err);
				});
		});
	}

	public logout(redirectUrl?: string): Promise<void> {
		this.cookieService.delete('implatform_tenantId');
		localStorage.removeItem('tenantId');
		localStorage.removeItem('keycloak-url');
		localStorage.removeItem('keycloak-realm');
		localStorage.removeItem('keycloak-client');
		localStorage.removeItem('keycloak-loginhint');
		localStorage.removeItem('keycloak-companyUUIdhint');
		if (this.keycloakService.getKeycloakInstance()) {
			let redirectUri = undefined;
			if (redirectUrl) redirectUri = this.getFullUrl(redirectUrl);
			window.location.href = this.createLogoutUrl(redirectUri);
		}
		return new Promise(async resolve => resolve());
	}

	public getCurrentUser(): string {
		return localStorage.getItem('keycloak-loginhint');
	}

	createLogoutUrl(redirectUrl) {
		var url =
			this.keycloakService
				.getKeycloakInstance()
				.createLogoutUrl()
				.substring(0, this.keycloakService.getKeycloakInstance().createLogoutUrl().indexOf('?')) +
			'?client_id=' +
			encodeURIComponent(this.keycloakService.getKeycloakInstance().clientId) +
			'&post_logout_redirect_uri=' +
			encodeURIComponent(redirectUrl);
		if (this.keycloakService.getKeycloakInstance().idToken) {
			url += '&id_token_hint=' + encodeURIComponent(this.keycloakService.getKeycloakInstance().idToken);
		}
		return url;
	}

	private config(url: string, realm: string, clientId: string, token?: string, refreshToken?: string): Promise<boolean> {
		let kc: KeycloakConfig = {
			url: url,
			realm: realm,
			clientId: clientId,
		};

		let kio: KeycloakInitOptions = {};
		if (token && refreshToken) {
			kio.token = token;
			kio.refreshToken = refreshToken;
			kio.checkLoginIframe = false;
		}

		return this.keycloakService.init({
			config: kc,
			initOptions: kio,
			enableBearerInterceptor: true,
			loadUserProfileAtStartUp: false,
			bearerExcludedUrls: this.getExcludedUrls(),
		});
	}

	private getFullUrl(path: string): string {
		let basePath = this.locationStrategy.getBaseHref();
		if (basePath.substring(basePath.length - 1) === '/') basePath = basePath.substring(0, basePath.length - 1);
		return location.origin + basePath + path;
	}
}
