import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONFIG } from 'src/app/app.config';
import { MultitenantService } from '../multitenant.service';
import { UserOutput } from '../../model/User.model';

@Injectable({
	providedIn: 'root',
})
export class UserManagementHttpService {
	private url = CONFIG.apiEndpoint + '/userMgmt';

	constructor(private http: HttpClient, private multiTenantService: MultitenantService) {}

	/**
	 * Checks against the backend if the given username exists already
	 *
	 * @param username username to be checked
	 * @returns boolean observable: true if found, false otherwise
	 */
	checkUsernameExists(username: string): Observable<boolean> {
		const url = this.url + '/existsUsername';

		return this.http.get<boolean>(url, {
			headers: this.multiTenantService.buildUnauthorizedHeaders(),
			params: {
				username: username,
			},
		});
	}

	/**
	 * Checks against the backend if the given email exists already
	 *
	 * @param email email code to be checked
	 * @returns boolean observable: true if found, false otherwise
	 */
	checkEmailExists(email: string): Observable<boolean> {
		const url = this.url + '/existsEmail';

		return this.http.get<boolean>(url, {
			headers: this.multiTenantService.buildUnauthorizedHeaders(),
			params: {
				email: email,
			},
		});
	}

	/**
	 * Checks against the backend if the given email exists already, when a user update his data
	 *
	 * @param email email code to be checked
	 * @returns boolean observable: true if found, false otherwise
	 */
	checkUpdateEmailExists(email: string): Observable<boolean> {
		const url = this.url + '/isValidEmailForUpdate';

		return this.http.get<boolean>(url, {
			headers: this.multiTenantService.buildUnauthorizedHeaders(),
			params: {
				email: email,
			},
		});
	}

	/**
	 * Obtain the data of the logged-in user
	 * @returns
	 */
	getUserLoggedIn(): Observable<UserOutput> {
		const url = this.url + '/userLogged';
		return this.http.get<UserOutput>(url, { headers: this.multiTenantService.buildHeaders() });
	}

	updateUser(user: UserOutput): Observable<void> {
		const url = this.url + '/userLogged';
		return this.http.put<void>(url, user, { headers: this.multiTenantService.buildHeaders() });
	}

  getRoles() {
    const url = this.url + '/userRoles';
    return this.http.get<string[]>(url, { headers: this.multiTenantService.buildHeaders() });
  }

  userInfo() {
    const url = this.url + '/userInfo';
    return this.http.get<UserOutput>(url, { headers: this.multiTenantService.buildHeaders() });
  }
}
