import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { KeycloakAuthGuard } from 'keycloak-angular';
import { Codes, PermissionsService, ServiceResponse, OUserInfoService } from 'ontimize-web-ngx';
import { MainService } from './services/main.service';
import { UserInfoService } from './services/user-info.service';
import { KeycloakService } from './services/keycloak.service';
import { Role } from './enums/role.enum';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UserManagementHttpService } from './services/http/user-management-http.service';
import { UserOutput } from './model/User.model';

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {
	constructor(
		protected router: Router,
		protected keycloakService: KeycloakService,
		protected permissionsService: PermissionsService,
		protected mainService: MainService,
		protected oUserInfoService: OUserInfoService,
		protected userInfoService: UserInfoService,
		protected domSanitizer: DomSanitizer,
    protected userManagementHttpService: UserManagementHttpService
	) {
		super(router, keycloakService.getKeycloak());
	}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
		if (this.keycloakService.getKeycloak().getKeycloakInstance()) {
			return await super.canActivate(route, state);
		} else {
			this.router.navigate([Codes.LOGIN_ROUTE], { queryParams: { redirect: state.url } });
		}
	}

	checkUserRole(role: string): boolean {
		let isRole: boolean = false;
		if (undefined != this.roles) {
			isRole = this.roles.indexOf(role) != -1;
		}
		return isRole;
	}

	isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		if (this.authenticated !== true) {
			this.router.navigate([Codes.LOGIN_ROUTE], { queryParams: { redirect: state.url } });
			return;
		}

		return new Promise(async (resolve, reject) => {
			let granted = false;
			await this.loadPermissions();
			const requiredRoles = route.data.roles;
			if (!requiredRoles || requiredRoles.length === 0) {
				granted = true;
			} else {
				for (const requiredRole of requiredRoles) {
					if (this.roles.indexOf(requiredRole) > -1) {
						granted = true;
						break;
					}
				}
			}

			if (granted === true) {
				this.loadUserInfo();
			} else {
				this.router.navigate(['/main']);
			}

			resolve(granted);
		});
	}

	public loadPermissions(): Promise<boolean> {
		if (!this.permissionsService.hasPermissions()) {
			return this.permissionsService.getUserPermissionsAsPromise();
		}
	}

  private loadUserInfo() {
    this.userManagementHttpService
      .userInfo()
      .pipe(
        catchError((error) => {
          this.keycloakService.logout(Codes.LOGIN_ROUTE);
          return throwError(() => new Error('Connection refused'));
        })
      )
      .subscribe((result: UserOutput) => {
        const userInfo = this.passUserOutputToUserInfo(result);

        if (userInfo) {
          this.userInfoService.storeUserInfo(userInfo);
          const avatar = './assets/images/user_profile.png';
          if (userInfo['usr_photo']) {
            (avatar as any) = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/*;base64,' + userInfo['usr_photo']);
          }
          this.oUserInfoService.setUserInfo({
            username: userInfo['usr_name'],
            avatar: avatar
          });
        }
      });
  }

  private passUserOutputToUserInfo(userOutput: UserOutput) {
    const userInfo: {usr_id: string, usr_name:string} = { usr_id: userOutput.username, usr_name: userOutput.firstName.concat(' ').concat(userOutput.lastName) };
    return userInfo;
  }

	isUserLogged(): boolean {
		if (undefined !== this.authenticated) {
			return this.authenticated;
		} else {
			return false;
		}
	}

	public getPrivateUrls() {
		return ['/main/kpi/kpi-home', '/main/offer/pending', '/main/demand/pending'];
	}
}
