import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppMenuService, AuthGuardService } from 'ontimize-web-ngx';
import { environment } from '../../../environments/environment';
import { AppAuthGuard } from '../appauthguard';
import { Role } from '../enums/role.enum';

export interface CustomMenuPrincipalItem {
  id: string;
  name: string;
  tooltip: string;
  icon: string;
  level: number;
  route?: string;
  items?: CustomMenuPrincipalItem[];
  fontSet?: string;
  opened?: boolean;
  selected?: boolean;
  action?: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class AriesConfigMenuSidebarService {

  private menuItemsSource = new BehaviorSubject<CustomMenuPrincipalItem[]>([]);
  menuItems$ = this.menuItemsSource.asObservable();

  booleanSubject = new BehaviorSubject<boolean>(true);
  public booleanObservable = this.booleanSubject.asObservable();
  private oAuthService: AuthGuardService;

  constructor(
    @Inject(AppMenuService) private appMenuService: AppMenuService,
  ) {}

  getBooleanSubject() {
    return this.booleanObservable;
  }

  getMenuItems(): CustomMenuPrincipalItem[] {
    return this.menuItemsSource.getValue();
  }

  buildMenuWithUserAuthenticated(roles): void {
    let urlPrefix = '';
    if (environment.production) {
      urlPrefix = '/app';
    }
    const menuItems = [];
    menuItems.splice(0, this.appMenuService.getMenuRoots().length);

    menuItems.push({
      id: 'myTenant',
      name: 'menu.my_tenant',
      tooltip: 'menu.my_tenant',
      icon: 'tune',
      route: '/main/tenantTheme',
    } as CustomMenuPrincipalItem);

    this.updateMenuItems(menuItems);
  }

  updateMenuItems(newMenuItems: CustomMenuPrincipalItem[]): void {
    this.menuItemsSource.next(newMenuItems);
  }

  updateSidenavState(value: boolean): void {
    this.booleanSubject.next(value);
  }

  delete(itemId: string) {
    this.updateMenuItems(this.removeMenuItemById(this.getMenuItems(), itemId));
  }

  removeMenuItemById(items: CustomMenuPrincipalItem[], id: string): CustomMenuPrincipalItem[] {
    return items.reduce<CustomMenuPrincipalItem[]>((acc, item) => {
      if (item.items) {
        item.items = this.removeMenuItemById(item.items, id);
        if (item.items.length === 0) {
          item.items = undefined;
        }
      }
      if (item.id !== id) {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  add(newItem: CustomMenuPrincipalItem, parentId?: string) {
    this.updateMenuItems(this.addMenuItemById(this.getMenuItems(), newItem, parentId));
  }

  addMenuItemById(items: CustomMenuPrincipalItem[], newItem: CustomMenuPrincipalItem, parentId?: string): CustomMenuPrincipalItem[] {
    if (!parentId) {
      items.push(newItem);
      return items;
    }

    return items.map(item => {
      if (item.id === parentId) {
        if (!item.items) {
          item.items = [];
        }
        item.items.push(newItem);
      } else if (item.items) {
        item.items = this.addMenuItemById(item.items, newItem, parentId);
      }
      return item;
    });
  }

  retainSpecificMenuItems(items: CustomMenuPrincipalItem[], keepIds: string[]): CustomMenuPrincipalItem[] {
    return items.reduce<CustomMenuPrincipalItem[]>((acc, item) => {
      if (keepIds.includes(item.id)) {
        acc.push(item);
        return acc;
      }
      if (item.items) {
        item.items = this.retainSpecificMenuItems(item.items, keepIds);
        if (item.items.length === 0) {
          item.items = undefined;
        }
      }
      return acc;
    }, []);
  }

  setAsSelectedItem(selectedItem: CustomMenuPrincipalItem) {
    const deselectAll = (items: CustomMenuPrincipalItem[]) => {
      items.forEach(item => {
        item.selected = false;
        if (item.items && item.items.length > 0) {
          deselectAll(item.items);
        }
      });
    };

    deselectAll(this.getMenuItems());
    selectedItem.selected = true;
  }

}

