import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppAuthGuard } from './shared/appauthguard';

export const routes: Routes = [
	{ path: 'main', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
	{ path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
	{ path: '', redirectTo: 'main', pathMatch: 'prefix' },
];

const opt = {
	enableTracing: false,
	relativeLinkResolution: 'legacy',
};

@NgModule({
	imports: [RouterModule.forRoot(routes, opt)],
	exports: [RouterModule],
	providers: [AppAuthGuard],
})
export class AppRoutingModule {}
